import React from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import SEO from 'components/Seo'
import { isBrowserIE } from 'utils/browser'
import 'assets/scss/pages/404.scss'

export default function NotFound() {
  const { t } = useTranslation('error')

  return (
    <div className={`PageNotFound-screen ${isBrowserIE() && 'ie'}`}>
      <div className="PageNotFound-container">
        <SEO title={t('lbl_not_found_title')} />
        <h4 className="PageNotFound-title">{t('lbl_not_found_title')}</h4>
        <p className="PageNotFound-desc">
          {t('lbl_click')}&nbsp;<span onClick={() => navigate('/')}>{t('lbl_here')}</span>&nbsp;
          {t('lbl_gohome')}
        </p>
      </div>
    </div>
  )
}
